import React from 'react'
import parse from 'html-react-parser'
import { Link } from 'gatsby'

import { Content, heroClasses, PersonContainer } from '../Atoms'
import { ContactForm } from '../Sections'
import Layout from '../Layout'

import { labels, localeUrls } from '../../langs/langs'

import phoneI from '../../images/phone-green.svg'
import atI from '../../images/at-green.svg'

const ContactUs = ({ localeID, location }) => {
  return (
    <Layout location={location} title={labels[localeID]['WORK_W_US']} localeID={localeID}>
      <div
        className={`${heroClasses} relative lg:h-[calc(100vh_-_10.75rem)] !bg-blue max-h-[52rem]`}
      >
        <PersonContainer className="h-[90%] top-auto" />
        <Content className="hcenter h-full text-white">
          <div>
            <a
              href="tel:+421 2 32 606 511"
              className="hcenter text-2xl sm:text-5xl font-bold sm:gap-6"
            >
              <img src={phoneI} className="w-9 h-9 sm:w-auto sm:h-auto" />
              +421(2) 32 606 511
            </a>
            <p className="py-9 max-w-md">{parse(labels[localeID]['CTA_HERO'])}</p>
            <a href="tel:+421 2 32 606 511" className="button2">
              {labels[localeID]['CALL_US']}
            </a>
          </div>
        </Content>
      </div>
      <div className="py-24 sm:py-32 bg-dark text-white">
        <Content>
          <a href="mailto:office@ccstax.sk" className="hcenter text-2xl sm:text-5xl font-bold">
            <img src={atI} className="w-9 h-9 sm:w-auto sm:h-auto" />
            office@ccstax.sk
          </a>
          <p className="py-9 max-w-md">{parse(labels[localeID]['WRITE_US_C'])}</p>
          <ContactForm localeID={localeID} className="lg:max-w-[66%]" light={false} />
        </Content>
      </div>
    </Layout>
  )
}

export default ContactUs
